import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
    Box,
    Button,
    Stack,
    Typography,
    useTheme,
    Slider,
    IconButton,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AvatarEditor from "react-avatar-editor";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useDispatch } from 'react-redux';  // for dispatching actions
import { addProfilePic } from "../../Services/Redux/Slices/User.Slice";
import { updateProfilePic } from "../../Services/APIHandlers/ProfileApi";
import config from '../../config';  // Assuming config contains the API URL
import AddIcon from '@mui/icons-material/Add';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';

const ProfilePicUpload = () => {
    const [file, setFile] = useState(null);
    const [scale, setScale] = useState(1); // For zooming with react-avatar-editor
    const editorRef = useRef(null);
    const theme = useTheme(); // Material-UI theme for dark/light mode
    const dispatch = useDispatch(); // Redux dispatch for updating state
    const token = useSelector((state) => state.auth.token); // Add logic to get the token from user session or context
    const profilePic = useSelector((state) => state.user.profilepic);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
    };

    const handleReset = () => {
        setFile(null); // Remove the file to reset the cropping tool
        setScale(1); // Reset scale to default
    };

    // Function to handle image save on checkmark click
    const handleSave = async () => {
        if (editorRef.current && file) {
            const canvas = editorRef.current.getImage();  // Get the cropped image from editor
            canvas.toBlob(async (blob) => {
                if (blob) {
                    const formData = new FormData();
                    formData.append("file", blob);  // Attach cropped image as blob
                    formData.append("token", token);

                    try {
                        const response = await fetch(
                            config.apiUrl + "Upload/singlePhotoUpload",
                            {
                                method: "POST",
                                body: formData,
                            }
                        );

                        if (response.ok) {
                            const result = await response.json();
                            console.log("Upload successful:", result);

                            const picture_id = result.data.picture_id;
                            const parsedUrls = JSON.parse(result.data.urls);
                            const pictureUrl = parsedUrls.original;

                            //console.log(picture_id, pictureUrl); 

                            dispatch(addProfilePic(pictureUrl));
                            // Call the updateProfilePic API function
                            await updateProfilePic(picture_id, token);
    
                            // Reset or refresh UI as needed
                            //window.location.reload();
                            
                            setFile(null);
                        } else {
                            console.error("Upload failed:", response.statusText);
                        }
                    } catch (error) {
                        console.error("Error during upload:", error.message);
                    }
                }
            }, 'image/png');  // Convert canvas to PNG Blob
        } else {
            console.warn("No image selected or editor is not available");
        }
    };

    // Background style for the profile image or default image
    const horBackgroundStyle = {
        backgroundImage: `url(${profilePic ? profilePic : require("../../assets/selfie.png")})`,
        height: "120px",
        width: "120px",
        borderRadius: "1em",
        backgroundSize: "cover",
        backgroundPosition: "center",
        border: '2px dashed #ffffff75',
        borderRadius: '50rem',
    };


    return (
        <Box>
            <Stack spacing={3} sx={{ textAlign: "left" }}>
                <Stack spacing={2}>
                    {!file && (
                        <>
                            <div
                                className="shadow"
                                style={horBackgroundStyle}
                                onClick={() => document.querySelector('input[type="file"]').click()}
                            >
                                {!profilePic && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100%',
                                            width: '100%',
                                        }}
                                    >
                                        {/* <AddIcon sx={{ fontSize: 50 }} /> "Add Image" icon */}
                                    </div>
                                )}
                            </div>
                            <Button
                                variant="outlined"
                                component="label"
                                startIcon={<AddToPhotosIcon/>}
                            >
                                Upload Picture
                                <input type="file" hidden onChange={handleFileChange} />
                            </Button>
                        </>
                    )}
                </Stack>



                {file && (
                    <div style={{ position: "relative", width: "100%", height: "400px" }}>
                        <AvatarEditor
                            ref={editorRef}
                            image={file}
                            width={250}
                            height={250}
                            border={50}
                            scale={scale}
                            rotate={0}
                            color={theme.palette.mode === "dark" ? [0, 0, 0, 0.6] : [255, 255, 255, 0.6]} // Adjust based on dark/light mode
                        />

                        <div
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "350px",
                                height: "350px",
                                pointerEvents: "none",
                                backgroundSize: "25px 25px",
                                opacity: "10%",
                                backgroundImage:
                                    "linear-gradient(to right, #ddd 1px, transparent 1px), linear-gradient(to bottom, #ddd 1px, transparent 1px)",
                            }}
                        ></div>
                    </div>
                )}

                {file && (
                    <Slider
                        value={scale}
                        min={1}
                        max={2}
                        step={0.01}
                        onChange={(e, newValue) => setScale(newValue)}
                        aria-labelledby="zoom-slider"
                        sx={{ mt: 0, color: theme.palette.primary.main }}
                    />
                )}

                {file && (
                    <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2 }}>
                        <IconButton onClick={handleReset} color="error">
                            <CloseIcon />
                        </IconButton>
                        <IconButton onClick={handleSave} color="success">
                            <CheckCircleIcon />
                        </IconButton>
                    </Stack>
                )}
            </Stack>
        </Box>
    );
};

export default ProfilePicUpload;
