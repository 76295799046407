import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParam } from "react-use";
import { Accordion, AccordionSummary, AccordionDetails, Typography, useTheme } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ProfileContact from "../../../Components/Profile/ProfileContact";
import ProfileBusiness from "../../../Components/Profile/ProfileBusiness";
import ProfileHeader from "../../../Components/Profile/ProfileHeader";
import ProfileSettings from "../../../Components/Profile/ProfileSettings";
import OnboardingGallery from "../../../Components/Onboarding/OnboardingGallery";
import { spacingStyles } from "../../../assets/styles/global";

const Profile = () => {
  const navigate = useNavigate();
  const tab = useSearchParam("t") || "Contact";
  const [showProfile, setShowProfile] = useState(tab);
  const theme = useTheme(); // Access the theme object

  const handleTabChange = (tab) => {
    navigate(`/Profile?t=${tab}`);
    setShowProfile(tab);
  };

  const renderProfileContent = () => {
    switch (showProfile) {
      case "Contact":
        return <ProfileContact />;
      case "Gallery":
        return (
          <div style={{ height: "70%" }}>
            <OnboardingGallery />
          </div>
        );
      case "Trainer":
        return <ProfileBusiness />;
      case "Settings":
        return <ProfileSettings />;
      default:
        return <ProfileContact />;
    }
  };

  const [isContactOpen, setIsContactOpen] = useState(true);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [isBusinessOpen, setIsBusinessOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const closeAccordion = (accordionName) => {
    switch (accordionName) {
      case "Contact":
        setIsContactOpen(false);
        break;
      case "Gallery":
        setIsGalleryOpen(false);
        break;
      case "Business":
        setIsBusinessOpen(false);
        break;
      case "Settings":
        setIsSettingsOpen(false);
        break;
      default:
        break;
    }
  };

  return (
    <div style={{ ...spacingStyles.desktopMaxWidth }}>
      <ProfileHeader showProfile={showProfile} setShowProfile={handleTabChange} />
      
      <Accordion 
        expanded={isContactOpen} 
        onChange={() => setIsContactOpen(!isContactOpen)}
        style={{ backgroundColor: theme.palette.background.halfpaper, boxShadow: "unset"   }} // Use MUI theme background color
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography varient="h1" color={theme.palette.text.primary}>Contact Info</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ProfileContact closeAccordion={() => closeAccordion("Contact")} />
        </AccordionDetails>
      </Accordion>

      <Accordion 
        expanded={isGalleryOpen} 
        onChange={() => setIsGalleryOpen(!isGalleryOpen)}
        style={{ backgroundColor: theme.palette.background.halfpaper, boxShadow: "unset"   }} // Use MUI theme background color
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography  varient="h1" color={theme.palette.text.primary}>Photo Library</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ height: "70%" }}>
            <OnboardingGallery />
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion 
        expanded={isBusinessOpen} 
        onChange={() => setIsBusinessOpen(!isBusinessOpen)}
        style={{ backgroundColor: theme.palette.background.halfpaper, boxShadow: "unset"  }} // Use MUI theme background color
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography varient="h1" color={theme.palette.text.primary} >Business Info</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ProfileBusiness closeAccordion={() => closeAccordion("Business")}  />
        </AccordionDetails>
      </Accordion>

      <Accordion 
        expanded={isSettingsOpen} 
        onChange={() => setIsSettingsOpen(!isSettingsOpen)}
        style={{ backgroundColor: theme.palette.background.halfpaper, boxShadow: "unset"  }} // Use MUI theme background color
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography varient="h1" color={theme.palette.text.primary} >Miscellaneous</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ProfileSettings />
        </AccordionDetails>
      </Accordion>

    </div>

  );
};

export default Profile;
