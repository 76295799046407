import { FaFacebookF, FaShareAlt, FaTiktok, FaYoutube } from "react-icons/fa";
import { MdIosShare } from "react-icons/md";
import { colors, globalStyles } from "../../../assets/styles/global";
import {  useSelector } from "react-redux";

import { useState } from "react";

const AboutSection = () => {
  const [currentTab, setCurrentTab] = useState("About Me");
  const Trainer = useSelector((state) => state.booking.selectedSite);

  const Tab = ({ label }) => {
    const selected = currentTab === label;

    return (
      <div
        style={{
          ...globalStyles.centerView,
          width: "50%",
          backgroundColor: selected ? colors.black.color : colors.green.color,
          borderTopWidth: 10,
          borderColor: !selected
            ? colors.darker_gray.color
            : colors.black.color,
          cursor: "pointer",
        }}
        onClick={() => setCurrentTab(label)}
      >
        <div
          style={{
            color: selected ? colors.green.color : colors.black.color,
            fontWeight: "bold",
            padding: 10,
          }}
        >
          {label.toUpperCase()}
        </div>
      </div>
    );
  };

  return (
    <div
      style={{ margin: 15, marginTop: 25, backgroundColor: colors.black.color }}
    >
      <div style={{ ...globalStyles.flexRow }}>
        <Tab label="About Me" />
        <Tab label="My Style" />
      </div>

      {currentTab === "About Me" && (
        <div>
          <div
            style={{ ...globalStyles.p, ...globalStyles.center, padding: 25 }}
          >
            I am passionate about helping my clients reach their fitness and
            health goals through positivity and encouragement. I have been a
            personal trainer for 5+ years working with clients of all fitness
            levels and ages.
          </div>
        </div>
      )}

      {currentTab === "My Style" && (
        <div
          style={{
            padding: 20,
            display: "flex",
            flexDirection: "column",
            gap: 20,
            width: "100%",
            textAlign: "left",
          }}
        >
          <div>
            <div style={{ ...globalStyles.p }}>Specializes in:</div>
            <div style={styles.featureBox}>
              <div style={styles.featureText}>Flexibility</div>
              <div style={styles.featureText}>Strength</div>
            </div>
          </div>

          <div>
            <div style={{ ...globalStyles.p }}>Coaching Method:</div>
            <div style={styles.featureBox}>
              <div style={styles.featureText}>One-on-one</div>
              <div style={styles.featureText}>Remote Instructions</div>
            </div>
          </div>

          <div>
            <div style={{ ...globalStyles.p }}>Motivation Objectives:</div>
            <div style={styles.featureBox}>
              <div style={styles.featureText}>A Competetion</div>
              <div style={styles.featureText}>Gym/studios</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const SocialSection = () => {

  return (
    <div style={{ ...globalStyles.flexRow, ...globalStyles.mt30, gap: 25 }}>
      <div style={styles.socialIcons}>
        <FaFacebookF size={25} color={colors.white.color} />
      </div>

      <div style={styles.socialIcons}>
        <FaShareAlt size={25} color={colors.white.color} />
      </div>

      <div style={styles.socialIcons}>
        <FaTiktok size={25} color={colors.white.color} />
      </div>

      <div style={styles.socialIcons}>
        <FaYoutube size={25} color={colors.white.color} />
      </div>
    </div>
  );
};

const ProfilePic = () => {

  const Trainer = useSelector((state) => state.booking.selectedSite);
  return (
    <div
      style={{
        ...globalStyles.centerView,
        ...globalStyles.mt30,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: 130,
          height: 130,
          borderRadius: "50%",
          overflow: "hidden",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <img
          src={Trainer.profile.image}
          style={{
            borderRadius: "50%",
            width: 130,
            height: 130,
          }}
          alt="Gym"
        />
      </div>

      <div style={{ ...globalStyles.mt20, ...globalStyles.mb20 }}>
        <div style={{ ...globalStyles.h1 }}>{Trainer.profile.name || ""}</div>
      </div>
    </div>
  );
};

const Header = () => {
  return (
    <div
      style={{
        ...globalStyles.flexRow,
        ...globalStyles.flexWrap,
        ...globalStyles.flexCenter,
        gap: 10,
      }}
    >
      <div style={{ ...globalStyles.flexRow }}>
        <div style={{ color: colors.primary.color }}>Certifications:</div>
        <div style={{ ...globalStyles.p }}>NASM, AAAI</div>
      </div>
      <div style={{ ...globalStyles.flexRow }}>
        <div style={{ color: colors.primary.color }}>Years Experience:</div>
        <div style={{ ...globalStyles.p }}>5-10</div>
      </div>

      <div style={{ ...globalStyles.flexRow }}>
        <div style={{ color: colors.primary.color }}>Languages:</div>
        <div style={{ ...globalStyles.p }}>English</div>
      </div>
    </div>
  );
};

export default function TrainerDetailsHeader({ Gym, showProfilePic = true }) {
  return (
    <div style={styles.container}>
      <div style={{ position: "absolute", top: 12, right: 20 }}>
        <MdIosShare size={35} color={colors.primary.color} />
      </div>

      {showProfilePic && <ProfilePic Gym={Gym} />}

      <Header />

      <SocialSection />

      <div style={{ width: "92%" }}>
        <AboutSection />
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.darker_gray.color,
    marginLeft: 20,
    marginRight: 20,
    borderRadius: 20,
    position: "relative",
    // width: "100%",
  },
  socialIcons: {
    backgroundColor: colors.primary.color,
    width: 40,
    height: 40,
    borderRadius: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  featureBox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 10,
    marginTop: 10,
  },
  featureText: {
    backgroundColor: colors.green50.color,
    color: colors.white.color,
    padding: 4,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 4,
    overflow: "hidden",
  },
};
