import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  birthday: undefined,
  gender: undefined,
  languages: [], 
  area: {
    hopper_question: "What area do you need most help with?",
    partner_question: "What area do you specialize in?",
    answers: [
      { val: "Flexibity", select: null },
      { val: "Strength", select: null },
      { val: "Endurance", select: null },
      { val: "Immune System", select: null },
      { val: "Athleticism", select: null },
    ],
  },
  coach: {
    hopper_question: "What personality type motivates you most?",
    partner_question: "What type of trainer are you?",
    answers: [
      { val: "Passionate Motivator", select: null },
      { val: "Chill and Supportive", select: null },
      { val: "Authoratative Trainer", select: null },
      { val: "The Educator", select: null },
      { val: "Role Model", select: null },
    ],
  },
  goal: {
    hopper_question: "What is your physical fitness goal?",
    partner_question: "What physical achievements do you help clients with?",
    answers: [
      { val: "Lose Weight", select: null },
      { val: "Build Muscle", select: null },
      { val: "Firm and Tone", select: null },
      { val: "Gain Flexibilty", select: null },
      { val: "Feel Better", select: null },
    ],
  },
  motivate: {
    hopper_question: "What is motivating you to be fit?",
    partner_question: "What motivation objectives do your clients have?",
    answers: [
      {
        val: "A Competition",
        select: null,
        example: "Marathon, Bodybuilding...",
      },
      {
        val: "A Special Occasion",
        select: null,
        example: "Wedding, Birthday...",
      },
      {
        val: "Social Outreach",
        select: null,
        example: "Extend life longevity, Increase Immnune...",
      },
      {
        val: "Hobbie Enthusiast",
        select: null,
        example: "Extend life longevity, Increase Immnune...",
      },
      {
        val: "Life Experiences",
        select: null,
        example: "Extend life longevity, Increase Immnune...",
      },
    ],
  },
  nonsuccess: {
    hopper_question: "What in those experiences did you find value in?",
    partner_question:
      "Which of these do you consider your training methods to be?",
    answers: [
      { val: "Adjustability", select: null },
      { val: "Personalization", select: null },
      { val: "Positive Reinforcements", select: null },
      { val: "Right Amount of Challenge", select: null },
      { val: "Enjoyable Activities", select: null },
    ],
  },
  success: {
    hopper_question: "What past fitness experiences have you found success in?",
    partner_question: "What is your primary method of coaching?",
    answers: [
      { val: "Gym/Studios", select: null },
      { val: "Organized Sporting Activies", select: null },
      { val: "One-on-One", select: null },
      { val: "Group Activities", select: null },
      { val: "Remote Instructions", select: null },
    ],
  },
  similarFitnessTrainers: [],
};

const MatchMeDataSlice = createSlice({
  name: "onboarding",
  initialState: initialState,
  reducers: {
    updateAnswer(state, action) {
      const { topic, index, answer } = action.payload;
      // Determine which topic to update based on the topic parameter

      switch (topic) {
        case "area":
          state.area.answers[index] = answer;
          break;
        case "coach":
          state.coach.answers[index] = answer;
          break;
        case "goal":
          state.goal.answers[index] = answer;
          break;
        case "motivate":
          state.motivate.answers[index] = answer;
          break;
        case "nonsuccess":
          state.nonsuccess.answers[index] = answer;
          break;
        case "success":
          state.success.answers[index] = answer;
          break;
        default:
          break;
      }
    },
    updateAreaAnswer(state, action) {
      const { index, answer } = action.payload;
      state.area.answers[index] = answer;
    },
    updateCoachAnswer(state, action) {
      const { index, answer } = action.payload;
      state.coach.answers[index] = answer;
    },
    updateGoalAnswer(state, action) {
      const { index, answer } = action.payload;
      state.goal.answers[index] = answer;
    },
    updateMotivateAnswer(state, action) {
      const { index, answer } = action.payload;
      state.motivate.answers[index] = answer;
    },
    updateNonsuccessAnswer(state, action) {
      const { index, answer } = action.payload;
      state.nonsuccess.answers[index] = answer;
    },
    updateSuccessAnswer(state, action) {
      const { index, answer } = action.payload;
      state.success.answers[index] = answer;
    },
    updateBirthday(state, action) {
      state.birthday = action.payload;
    },
    updateGender(state, action) {
      state.gender = action.payload;
    },
    updateLanguages: (state, action) => {
      state.languages = action.payload;
    },
    setSimilarFitnessTrainers(state, action) {
      state.similarFitnessTrainers = action.payload;
    },
  },
});

export const {
  updateAreaAnswer,
  updateCoachAnswer,
  updateGoalAnswer,
  updateMotivateAnswer,
  updateNonsuccessAnswer,
  updateSuccessAnswer,
  updateAnswer,
  updateBirthday,
  updateGender,
  setSimilarFitnessTrainers,
  updateLanguages,
} = MatchMeDataSlice.actions;

export default MatchMeDataSlice.reducer;
