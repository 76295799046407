import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { padStart } from "lodash";
import { FaTransgender, FaCheckCircle } from "react-icons/fa";
import { IoMdMale, IoMdFemale } from "react-icons/io";
import {
  updateBirthday,
  updateGender,
  updateLanguages,
} from "../../Services/Redux/Slices/MatchMe.Slice"; // Import the appropriate actions
import { Autocomplete, TextField } from "@mui/material";
import { colors, globalStyles } from "../../assets/styles/global";
import moment from "moment";
import CustomDateTimePicker from "../Common/CustomDatePicker";

const genderOptions = [
  {
    label: "Male",
    icon: <IoMdMale size={45} color={colors.white.color} />,
  },
  {
    label: "Female",
    icon: <IoMdFemale size={45} color={colors.white.color} />,
  },
  {
    label: "Other",
    icon: <FaTransgender size={45} color={colors.white.color} />,
  },
];

const languageOptions = [
  { label: "English", native: "English" },
  { label: "Spanish", native: "Español" },
  { label: "French", native: "Français" },
  { label: "German", native: "Deutsch" },
  { label: "Mandarin", native: "中文 (Zhōngwén)" },
  { label: "Russian", native: "Русский" },
  { label: "Arabic", native: "العربية" },
  { label: "Hindi", native: "हिन्दी" },
  { label: "Portuguese", native: "Português" },
  { label: "Japanese", native: "日本語 (Nihongo)" },
  { label: "Korean", native: "한국어 (Hanguk-eo)" },
  { label: "Italian", native: "Italiano" },
  { label: "Dutch", native: "Nederlands" },
  { label: "Greek", native: "Ελληνικά (Elliniká)" },
  { label: "Turkish", native: "Türkçe" },
  { label: "Creole", native: "Kreyòl" },
  { label: "Patois", native: "Patois" }
];

const DemographicInfoComponent = () => {
  const dispatch = useDispatch();

  const matchMeData = useSelector((state) => state.matchMe);
  const Gender = matchMeData.gender;

  const [date, setDate] = useState(
    matchMeData.birthday ? new Date(matchMeData.birthday) : null
  );
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
  const [selectedLanguages, setSelectedLanguages] = useState(
    matchMeData.languages || []
  );

  const handleConfirm = (date) => {
    setDate(date);
    dispatch(updateBirthday(moment(date).format("yyyy-MM-DD")));
    setDatePickerVisibility(false);
  };

  const month = useMemo(() => {
    return date ? padStart(String(moment(date).month() + 1), 2, "0") : " ";
  }, [date]);

  const day = useMemo(() => {
    return date ? padStart(String(moment(date).date()), 2, "0") : " ";
  }, [date]);

  const year = useMemo(() => {
    return date ? String(moment(date).year()) : " ";
  }, [date]);

  const handleLanguageChange = (event, value) => {
    setSelectedLanguages(value);
    dispatch(updateLanguages(value.map((lang) => lang.label)));
  };

  return (
    <div
      style={{
        ...globalStyles.w100,
        ...globalStyles.alignCenter,
        ...globalStyles.pt10,
      }}
    >
      {/* Birthday Picker */}
      <div style={{ ...globalStyles.mb40 }}>
        <div style={{ visibility: "hidden", height: 0 }}>
          <CustomDateTimePicker
            isVisible={isDatePickerVisible}
            date={date || new Date()}
            onConfirm={handleConfirm}
            onClose={() => setDatePickerVisibility(false)}
          />
        </div>

        <div style={{ ...globalStyles.flexRow, ...globalStyles.mb10 }}>
          <FaCheckCircle
            size={38}
            color={date ? colors.green.color : colors.dark_gray.color}
            style={{ ...globalStyles.mr15 }}
          />
          <div style={{ ...globalStyles.h1, ...globalStyles.pb20 }}>
            When is your birthday?
          </div>
        </div>

        <div onClick={() => setDatePickerVisibility(true)}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: 20,
            }}
          >
            <div style={{ alignItems: "center", gap: 15 }}>
              <div
                style={{
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: "#fff",
                  borderRadius: 10,
                  color: "#fff",
                  backgroundColor: "#333",
                  textAlign: "center",
                  padding: 10,
                  fontSize: 25,
                  minWidth: 60,
                  minHeight: 32,
                  marginBottom: 10,
                }}
              >
                {month}
              </div>
              <div style={{ color: "#fff" }}>Month</div>
            </div>

            <div style={{ alignItems: "center", gap: 15 }}>
              <div
                style={{
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: "#fff",
                  borderRadius: 10,
                  color: "#fff",
                  backgroundColor: "#333",
                  textAlign: "center",
                  padding: 10,
                  fontSize: 25,
                  minWidth: 60,
                  minHeight: 32,
                  marginBottom: 10,
                }}
              >
                {day}
              </div>
              <div style={{ color: "#fff" }}>Day</div>
            </div>

            <div style={{ alignItems: "center", gap: 15 }}>
              <div
                style={{
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: "#fff",
                  borderRadius: 10,
                  color: "#fff",
                  backgroundColor: "#333",
                  textAlign: "center",
                  padding: 10,
                  fontSize: 25,
                  minWidth: 90,
                  minHeight: 32,
                  marginBottom: 10,
                }}
              >
                {year}
              </div>
              <div style={{ color: "#fff" }}>Year</div>
            </div>
          </div>
        </div>
      </div>

      {/* Gender Picker */}
      <div style={{ ...globalStyles.flexRow,  }}>
        <FaCheckCircle
          size={38}
          color={Gender ? colors.green.color : colors.dark_gray.color}
          style={{ ...globalStyles.mr15 }}
        />
        <div style={{ ...globalStyles.h1, ...globalStyles.pb20 }}>
          What is your gender?
        </div>
      </div>

      <div
        className="flexRow alignCenter"
        style={{ justifyContent: "center", gap: 20 }}
      >
        {genderOptions.map(({ label, icon }) => (
          <div
            key={label}
            onClick={() => {
              if (Gender !== label) {
                dispatch(updateGender(label));
              } else {
                dispatch(updateGender(undefined));
              }
            }}
          >
            <div
              style={{
                ...globalStyles.flexCenter,
                ...globalStyles.alignCenter,
                ...styles.genderBox,
                backgroundColor:
                  Gender === label ? colors.green50.color : "#222",
                cursor: "pointer",
              }}
            >
              {icon}
              <div style={{ color: colors.white.color }}>{label}</div>
            </div>
          </div>
        ))}
      </div>

      {/* Language Picker */}
      <div style={{ marginTop: 60 }}>
        <div style={{ ...globalStyles.flexRow }}>
          <FaCheckCircle
            size={38}
            color={Gender ? colors.green.color : colors.dark_gray.color}
            style={{ ...globalStyles.mr15 }}
          />
          <div style={{ ...globalStyles.h1, ...globalStyles.pb20 }}>
            What languages do you speak?
          </div>
        </div>
        <Autocomplete
          multiple
          options={languageOptions}
          getOptionLabel={(option) => option.native} // Shows the native language names
          value={selectedLanguages}
          onChange={handleLanguageChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Select Languages"
              placeholder="Languages"
              style={{ backgroundColor: "#333", color: "#fff" }}
              InputLabelProps={{
                style: { color: "#fff" },
              }}
              InputProps={{
                ...params.InputProps,
                style: { color: "#fff" },
              }}
            />
          )}
          sx={{
            "& .MuiAutocomplete-tag": {
              backgroundColor: "#555", // Styling for the selected items (tags)
              color: "#fff",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#fff",
              },
              "&:hover fieldset": {
                borderColor: "#ccc",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#90caf9",
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default DemographicInfoComponent;

const styles = {
  genderBox: {
    gap: 10,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#ccc",
    borderRadius: 12,
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: "#222",
  },
};
