
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { AuthWrapper } from './Navigation/AuthWrapper';
import Footer from './Navigation/Footer';
import { ThemeProvider } from '@mui/material/styles';
import { lightTheme, darkTheme } from './assets/styles/global';
import { useState, useEffect } from 'react';

function App() {
  const [darkMode, setDarkMode] = useState(true);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [darkMode]);


  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <div className="App">
        <BrowserRouter>
          <AuthWrapper darkMode={darkMode} toggleDarkMode={toggleDarkMode} setDarkMode={setDarkMode} />
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
