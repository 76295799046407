import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  verify_SMSPin,
  emailOTP,
  verify_EmailPin,
} from "../../Services/APIHandlers/AuthApi";
import { addBusiness } from "../../Services/Redux/Slices/Business.Slice";
import {
  createAuth,
  addAccountData,
} from "../../Services/Redux/Slices/Auth.Slice";
import { addUser } from "../../Services/Redux/Slices/User.Slice";
import { addBookingList } from "../../Services/Redux/Slices/Booking.Slice";
import { Box, TextField, Typography } from "@mui/material"; // MUI components
import { Button } from "../Common/Buttons/Button"; // Custom Button component
import { spacingStyles } from "../../assets/styles/global";
import { useTheme } from '@mui/material/styles';


const OTPForm = ({ onSuccess, mode = "dark" }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const phone = useSelector((state) => state.user.phone);
  const [sendCodeTo, setSend] = useState("text");

  const theme = useTheme();

  const getEmailOtp = async () => {
    setSend("email");

    try {
      await emailOTP(token);
    } catch (err) {
      console.error(err);
    }
  };

  const submitPin = async () => {
    console.log(value); 
    if (value.length !== 4) {
      setError("Invalid Pin Code");
      return;
    }
    setError("");

    try {
      let response;

      if (sendCodeTo === "email") {
        response = await verify_EmailPin(value, token);
      } else {
        response = await verify_SMSPin(value, token);
      }

      if (response.data?.status === "invalid") {
        setError("Invalid Pin Code");
        return;
      }

      await dispatch(addUser({ ...response.data.userData }));

      if (
        response.data.userData.role_id === "2" ||
        response.data.userData.role_id === "3"
      ) {
        await dispatch(addBusiness({ ...response.data.businessData }));
        await dispatch(addBookingList({ ...response.data.businessData }));
        await dispatch(addAccountData({ ...response.data.accountData }));
      }

      await dispatch(
        createAuth({ token: token, role_id: response.data.userData.role_id })
      );
      onSuccess(response.data.userData.role_id);
    } catch (err) {
      console.error(err);
    }
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    // Restrict to numbers and max length of 4
    if (/^\d*$/.test(inputValue) && inputValue.length <= 4) {
      setValue(inputValue);
    }
  };

  return (
    <Box sx={{ textAlign: "center", ...spacingStyles.formSpace }}>
      <Typography variant="h6" color={theme.palette.text.secondary} gutterBottom>
        Enter the verification code we {sendCodeTo}ed to you.
      </Typography>
      {error && (
        <Typography color="error" variant="body1">
          {error}
        </Typography>
      )}
      {phone && (
        <Typography sx={{ pt: '40px' }} variant="body1" color="textSecondary">
          Pin was sent to {phone}
        </Typography>
      )}

      <Box sx={{ display: "flex", justifyContent: "center", mt: 3, mb: 3 }}>
        <TextField
          variant="outlined"
          value={value} // Bind the input to the value state
          onChange={handleChange} // Update the value on change
          inputProps={{
            maxLength: 4,
            style: { textAlign: "center", fontSize: "24px", letterSpacing: "10px" }, // Center text and adjust spacing for clarity
          }}
          sx={{
            width: "220px", // Enough width for 4 digits with some spacing
            "& input": {
              padding: "10px",
            },
          }}
          autoFocus
        />
      </Box>

      <Box sx={{ mt: 4 }}>
        <Button fill="solid" text="Verify ID" onClick={submitPin} />
      </Box>

      <Typography variant="h6" sx={{ mt: 4 }}>
        Didn't receive our text?
      </Typography>
      <Typography variant="body2" color="textSecondary" sx={{ pt: 1 }}>
        <span className="textButton" style={{ color: "orange", cursor: "pointer" }} onClick={getEmailOtp}>
          Email me
        </span>{" "}
        the verification code.
      </Typography>
    </Box>
  );
};

export default OTPForm;
