import React, { useState, useEffect } from "react";
import { IoIosArrowDropleft } from "react-icons/io";
import { spacingStyles } from "../../assets/styles/global";

const SlideInPanel = ({ mode, isOpen, closePanel, content: Content, onSuccess,  }) => {

  const panelStyles = {
    position: "fixed",
    top: "0px",
    width: "100vw",
    minHeight: "100%",
    transition: "right 0.3s ease-in-out 0s",
    zIndex: 999,
    right: isOpen ? "0px" : "-100vw",
    backgroundColor: mode === "light" ? "rgba(255, 255, 255, 1)" : "rgba(0, 0, 0, 1)" ,
  };

  const navbarStyles = {
    ...spacingStyles.mauto,
    backgroundColor: mode === "light" ? "rgba(255, 255, 255, 1)" : "rgba(0, 0, 0, 1)" ,
  };

  // useEffect to scroll to top when panel opens
  // useEffect(() => {
  //   if (isOpen) {
  //     // Scroll to the top of the panel
  //     const panelElement = document.getElementById("slide-in-panel");
  //     console.log("Scroll to top"); 
  //     if (panelElement) {
  //       panelElement.scrollTop = 0;
  //     }
  //   }
  // }, [isOpen]);
  useEffect(() => {
    if (isOpen) {
      const scrollableElement = document.querySelector(".hidden-scroll");
      if (scrollableElement) {
        scrollableElement.scrollTop = 0;
      }
    }
  }, [isOpen]);

  return (
    <div id="slide-in-panel" className="fullPageSlider" style={panelStyles}>
      <div className="navbar" style={navbarStyles}>
        <IoIosArrowDropleft
          name="arrow-back-circle-outline"
          size={37}
          color="#707070"
          onClick={closePanel}
        ></IoIosArrowDropleft>
      </div>
      <div
        className="hidden-scroll"
        style={{
          ...spacingStyles.desktopMaxWidth,
          height: "100vh",
        }}
      >
        <div
          className=""
          style={{
            height: "90vh",
            ...spacingStyles.pb40,
            ...spacingStyles.p10,
            ...spacingStyles.mt10h,
          }}
        >
          <Content onSuccess={onSuccess} />
        </div>
      </div>
    </div>
  );
};

export default SlideInPanel;
