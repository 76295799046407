import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles"; // Use the theme to get current mode
import mapboxgl from "mapbox-gl";
import { fetchSitesNearMe } from "../../Services/APIHandlers/BookingApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEarthAmericas, faBars } from "@fortawesome/free-solid-svg-icons";
import StarIcon from "@mui/icons-material/Star";
import "../../assets/styles/global.css";
import OnboardingButton from "../../Components/Common/Buttons/OnboardingButton";
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';

mapboxgl.accessToken =
  "pk.eyJ1IjoiaG9wcGVyZml0IiwiYSI6ImNsa2tpM2c0ZzA2dWkzcG1tNnV3MmVyMjQifQ.x9vQVrNmILfTln-SAXS8pw";

export default function MapSearch() {
  const location = useSelector((state) => state.location);
  const scrollableDivRef = useRef(null);
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-80.0534);
  const [lat, setLat] = useState(26.7153);
  const [zoom, setZoom] = useState(9);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [markers, setMarkers] = useState(); // Gyms (studios)
  const [trainers, setTrainers] = useState(); // Trainers
  const [showList, setShowList] = useState(false);
  const [selectedType, setSelectedType] = useState("studio"); // 'studio' or 'trainer'
  const theme = useTheme(); // Access the current theme
  const mode = theme.palette.mode; // Determine if the mode is 'dark' or 'light'

  const mapStyle =
    mode === "dark"
      ? "mapbox://styles/mapbox/dark-v11"
      : "mapbox://styles/mapbox/light-v10"; // Choose map style based on mode

  const getSites = async () => {
    try {
      if (location?.coords?.latitude && location?.coords?.longitude) {
        const response = await fetchSitesNearMe(
          location?.coords?.latitude,
          location.coords.longitude
        );
        //console.log(response.data);
        // Filter the response data for studios and trainers
        const studios = response.data.filter((item) => item.type === "studio");
        const trainers = response.data.filter((item) => item.type === "trainer");

        // Set state with filtered data
        setMarkers(studios); // Set markers where type is 'studio'
        setTrainers(trainers); // Set trainers where type is 'trainer'
      }
    } catch (err) {
      console.error(err);
    }
  };

  const calculateBoundingBox = (locations, userLocation = location) => {
    // Ensure user location is valid
    const userLat = parseFloat(userLocation.address.lat);
    const userLng = parseFloat(userLocation.address.lng);

    if (!userLat || !userLng || isNaN(userLat) || isNaN(userLng)) {
      throw new Error("Invalid user location: latitude or longitude is NaN");
    }

    // Convert degrees to radians for lat/lng calculations
    const toRadians = (degrees) => degrees * (Math.PI / 180);

    // Filter out locations with invalid lat, long, or distance
    const validLocations = locations.filter(({ location }) => {
      const { lat, long, distance } = location[0];
      return !isNaN(parseFloat(lat)) && !isNaN(parseFloat(long)) && !isNaN(parseFloat(distance));
    });

    if (validLocations.length === 0) {
      throw new Error("No valid locations available to calculate bounding box");
    }
    //validLocations.map(({ location }) =>  console.log(location[0].distance) ); 
    // Get the maximum distance (in miles) from the valid locations
    const maxDistance = Math.max(...validLocations.map(({ location }) => parseFloat(location[0].distance)));

    // Ensure maxDistance is reasonable and not overly large
    if (maxDistance <= 0 || maxDistance > 10000) {
      throw new Error("Max distance is invalid or excessively large");
    }

    // 1 degree of latitude is approximately 69 miles, and for longitude, it varies with latitude
    const latAdjustment = maxDistance / 69; // 1 degree of latitude is about 69 miles
    const lngAdjustment = maxDistance / (69 * Math.cos(toRadians(userLat))); // 1 degree of longitude depends on latitude

    // Calculate the bounding box
    const minLat = userLat - latAdjustment;
    const maxLat = userLat + latAdjustment;
    const minLng = userLng - lngAdjustment;
    const maxLng = userLng + lngAdjustment;

    // Ensure minLat, maxLat, minLng, maxLng are within valid ranges for latitude (-90 to 90) and longitude (-180 to 180)
    if (minLat < -90 || maxLat > 90 || minLng < -180 || maxLng > 180) {
      throw new Error("Calculated bounding box is out of bounds for valid latitude/longitude");
    }

    // Center of the bounding box
    const centerLat = (minLat + maxLat) / 2;
    const centerLng = (minLng + maxLng) / 2;

    // Return the center and maxDistance for the radius in meters
    //console.log(maxDistance);
    return {
      center: [centerLng, centerLat],
      //radius: maxDistance * 1609.34, // Convert miles to meters for Mapbox circle radius
      radius: 16 * 1609.34,
    };
  };

  // Function to generate a GeoJSON circle polygon
  const createGeoJSONCircle = (center, radiusInMeters, numPoints = 64) => {
    const [centerLng, centerLat] = center;
    const coords = [];
    const distanceX = radiusInMeters / (111.32 * 1000); // 1 degree latitude ≈ 111.32 km
    const distanceY = radiusInMeters / (111.32 * 1000 * Math.cos((centerLat * Math.PI) / 180)); // Adjust for longitude

    for (let i = 0; i < numPoints; i++) {
      const theta = (i / numPoints) * (2 * Math.PI);
      const x = distanceX * Math.cos(theta);
      const y = distanceY * Math.sin(theta);
      coords.push([centerLng + x, centerLat + y]);
    }

    coords.push(coords[0]); // Close the polygon by connecting the last point to the first point

    return {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [coords],
      },
    };
  };

  const addCircleLayer = (center, radius) => {
    //console.log(center, radius);

    // Remove existing circle layer and source if they exist
    if (map.current.getLayer("circle-radius")) {
      map.current.removeLayer("circle-radius");
    }
    if (map.current.getSource("circle-radius")) {
      map.current.removeSource("circle-radius");
    }

    // Generate a GeoJSON circle polygon with the radius in meters
    const circleGeoJSON = createGeoJSONCircle(center, radius);

    // Add the GeoJSON source with the circle polygon
    map.current.addSource("circle-radius", {
      type: "geojson",
      data: circleGeoJSON,
    });
    //console.log(theme.palette.primary.light);
    // Add a fill layer to display the circle
    map.current.addLayer({
      id: "circle-radius",
      type: "fill",
      source: "circle-radius",
      paint: {
        "fill-color": hexToRgba(theme.palette.primary.light, 0.73),
        "fill-opacity": 0.3,
      },
    });
  };

  const hexToRgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  };

  useEffect(() => {
    if (map.current) {
      map.current.remove(); // Destroy the map instance before re-initializing
    }
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: mapStyle, // Use dynamic map style
      center: [lng, lat],
      zoom: zoom,
    });

    map.current.on("load", () => {
      getSites();
    });
  }, [mapStyle, selectedType]); // Re-initialize map when mapStyle changes

  useEffect(() => {
    if (selectedType === "trainer" && trainers?.length > 0 && map.current.isStyleLoaded()) {
      try {
        // Calculate the bounding box and radius
        const { center, radius } = calculateBoundingBox(trainers);

        // Check if the source already exists, remove it if so
        if (map.current.getSource("circle-radius")) {
          map.current.removeLayer("circle-radius");
          map.current.removeSource("circle-radius");
        }

        // Add a new circle source and layer
        addCircleLayer(center, radius);

        // Fly to the center of the circle
        map.current.flyTo({ center, zoom: 9 });
      } catch (error) {
        console.error(error.message);
      }
    } else if (selectedType === "studio") {
      // Remove the circle layer and source if switching to "studio"
      if (map.current.getLayer("circle-radius")) {
        map.current.removeLayer("circle-radius");
      }
      if (map.current.getSource("circle-radius")) {
        map.current.removeSource("circle-radius");
      }
    }
  }, [selectedType, trainers]);

  useEffect(() => {
    if (map.current && markers) {
      markers.forEach((markerInfo) => {
        const el = document.createElement("div");
        el.style.backgroundImage = `url(${markerInfo.image})`;
        el.style.width = `40px`;
        el.style.height = `40px`;
        el.style.backgroundSize = "cover";
        el.style.backgroundPosition = "center";
        el.style.borderRadius = "50px";
        el.style.borderColor = "#525252";
        el.style.borderStyle = "solid";
        el.style.borderWidth = "3px";

        new mapboxgl.Marker(el)
          .setLngLat([markerInfo.location[0].long, markerInfo.location[0].lat])
          .addTo(map.current);

        el.addEventListener("click", () => {
          setSelectedMarker(markerInfo);
          window.open("/" + markerInfo.slug);
        });
      });
    }
  }, [markers]);

  const roundToHalf = (num) => {
    return Math.round(num * 2) / 2; // Round to the nearest 0.5
  };
  const displayDistance = (distance) => {
    const parsedDistance = parseFloat(distance);
    
    if (isNaN(parsedDistance)) {
      return "Unknown Distance"; // Handle invalid distance
    }
    return parsedDistance > 100 ? "Far Far Away" : `${parsedDistance.toFixed(1)} miles`;
    return parsedDistance < 1 ? "Near By" : `${parsedDistance.toFixed(1)} miles`;
  };

  const ScrollableList = ({ data }) => {
    return (
      <ul className="scrollableList">
        {data?.map((item, index) => {
          const rating = item.rating !== null ? roundToHalf(item.rating) : 5; // Default to 5 if rating is null
          const isTrainer = item.type === "trainer"; // Check if item type is 'trainer'
  
          return (
            <li
              key={index}
              onClick={() => {
                map.current.flyTo({
                  center: [item.location[0].long, item.location[0].lat],
                  zoom: 14,
                });
                setSelectedMarker(item);
                window.open("/" + item.slug);
              }}
            >
              <img 
                src={item.image} 
                alt="Logo" 
                style={{
                  borderRadius: isTrainer ? '50rem' : '0',  // Apply round borders for trainers
                  aspectRatio: isTrainer ? '1' : 'unset',    // Apply aspect ratio for trainers
                  maxWidth: isTrainer ? '140px' : 'unset',   // Apply max-width for trainers
                }}
              />
              <div>
                <p>{item.name}</p>
                <p>
                  {item.meta.length > 25 ? item.meta.substring(0, 20) + "..." : item.meta}
                </p>
                {/* Limit to 25 characters and show ellipsis if exceeded */}
                <div className="sepRow">
                  <p>
                    {rating} <StarIcon style={{ color: theme.palette.primary.main }} />
                    {/* Display rating and star */}
                  </p>
                  <p style={{ color: 'var(--white)' }}>
                    {displayDistance(item.location[0].distance)} <PersonPinCircleIcon style={{ color: 'var(--white)' }} />
                  </p>
                </div>
              </div>
            </li>
          );
        })}
        <li className="scrollable-list-space"></li>
      </ul>
    );
  };
  

  const ToggleButton = () => {
    const toggleHandler = (type) => {
      setSelectedType(type);
      // Reinitialize the map when toggle is clicked to reset icons and layers
      if (map.current) {
        map.current.remove();
      }

      // Reinitialize the map
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: mapStyle,
        center: [lng, lat],
        zoom: zoom,
      });
    };

    return (
      <div className="toggle-container">
        <OnboardingButton
          selected={selectedType === "trainer"}
          text="Hoppers"
          onClick={() => toggleHandler("trainer")}
          showCusIcon={false}
          cusIconName="treadmill" // Assuming treadmill icon for Hoppers
          iconWidth={25}
          iconHeight={25}
        />
        <OnboardingButton
          selected={selectedType === "studio"}
          text="HopperSites"
          onClick={() => toggleHandler("studio")}
          showCusIcon={false}
          cusIconName="location" // Assuming location icon for Sites
          iconWidth={25}
          iconHeight={25}
        />
      </div>
    );
  };
  return (
    <div className="flexCol">
      <ToggleButton />
      <div className="search-container">
        <div style={{ position: "relative", overflow: "hidden", marginLeft: "auto", width: "100%" }}>
          <div
            className={`search-list-container ${showList ? "clicked" : ""}`}
            ref={scrollableDivRef}
          >
            <ScrollableList
              data={selectedType === "studio" ? markers : trainers}
            />
          </div>
        </div>
        <div ref={mapContainer} className="map-container" />
      </div>
    </div>
  );
}
