import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Stack } from "@mui/material";
import ProfileFieldInput from "./ProfileFieldInput";
import SaveIcon from '@mui/icons-material/Save';
import { updateContactAsync } from "../../Services/Redux/Slices/Contact.Slice";
import { addContact } from "../../Services/Redux/Slices/Contact.Slice";
import { Button } from "../Common/Buttons/Button";


const ProfileContact = ({ closeAccordion }) => {
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.user);
  const trainer = useSelector((state) => state.business);
  const dispatch = useDispatch();

  // Define states for form fields
  const [formData, setFormData] = useState({
    name: user.user_name || "",
    lastname: user.user_lastname || "",
    instagram: trainer.instagram || "",
    website: trainer.website || "",
    facebook: trainer.facebook || "",
    youtube: trainer.youtube || "",
    tiktok: trainer.tiktok || "",
  });

  const [isChanged, setIsChanged] = useState(false);

  // useEffect(() => {
  //   // Check if any changes have been made to the formData compared to the original user data
  //   const hasChanges = Object.keys(formData).some(key => formData[key] !== (user[key] || trainer[key]));
  //   console.log(hasChanges); 
  //   setIsChanged(hasChanges);
  // }, [formData, user, trainer]);

  const handleInputChange = (field, value) => {
    setIsChanged(true);
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleSave = async () => {
    //console.log("Form Data before save:", formData); // Log form data for debugging
  
    try {
      const response = await dispatch(updateContactAsync({ data: formData, token })).unwrap();
      if (response.success) {
        dispatch(addContact({ ...formData }));
        setIsChanged(false);
        closeAccordion();  
      }
    } catch (error) {
      console.error("Error saving profile data:", error);
    }
  };

  return (
    <>
          {isChanged && (
         <Button fill="solid" text="Save" onClick={handleSave} />
      )}
      <Stack spacing={5} pt={5} pb={5} mb={5} sx={{ margin: "auto" }}>
        <ProfileFieldInput
          label="Name"
          value={formData.name}
          isEditing={true}
          onChange={(e) => handleInputChange("name", e.target.value)}
        />
        <ProfileFieldInput
          label="Last Name"
          value={formData.lastname}
          isEditing={true}
          onChange={(e) => handleInputChange("lastname", e.target.value)}
        />
        <ProfileFieldInput
          label="Instagram"
          value={formData.instagram}
          isEditing={true}
          onChange={(e) => handleInputChange("instagram", e.target.value)}
        />
        <ProfileFieldInput
          label="Website"
          value={formData.website}
          isEditing={true}
          onChange={(e) => handleInputChange("website", e.target.value)}
        />
        <ProfileFieldInput
          label="Facebook"
          value={formData.facebook}
          isEditing={true}
          onChange={(e) => handleInputChange("facebook", e.target.value)}
        />
        <ProfileFieldInput
          label="YouTube"
          value={formData.youtube}
          isEditing={true}
          onChange={(e) => handleInputChange("youtube", e.target.value)}
        />
        <ProfileFieldInput
          label="TikTok"
          value={formData.tiktok}
          isEditing={true}
          onChange={(e) => handleInputChange("tiktok", e.target.value)}
        />
      </Stack>
    </>
  );
};

export default ProfileContact;
