import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

// Styles
import { colors, spacingStyles } from "../../../assets/styles/global";
import "../../../assets/styles/global.css";

// Components
import GymDetailsHeader from "../../../Components/Gym/GymDetailsHeader.js";
import RenderIcons from "../../../Components/Common/RenderIcons.js";
import { CustIcons } from "../../../Services/Constants/customIcons.js";
import Checkout from "../../Public/Checkout.js";

// API
import { getMockData } from "../../../Services/APIHandlers/BookingApi.js";
import { Button } from "../../../Components/Common/Buttons/Button.js";
import StarIcon from "@mui/icons-material/Star";

import SlideInPanel from "../../../Components/Modals/slideInPanel.js";
import DetailsSchedule from "../../../Components/Calendar/DetailsSchedule.js";

import AnimatedBackground from "../../../Components/Backgrounds/SlidingDiagonals";

import TrainerList from "../../../Components/List/TrainerList";
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/material";

const GymDetails = ({ Data }) => {
  //const dispatch = useDispatch();

  const amenities = {
    aerialYoga: true,
    basketballCourt: false,
    danceFloor: true,
    dancePoles: false,
    indoorField: false,
    indoorTrack: false,
    outdoorField: false,
    outdoorTrack: true,
    pool: false,
    racketballCourt: false,
    tennisCourt: false,
    trampoline: false,
    volleyballCourt: false,
  };
  const facilities = {
    bathroom: true,
    changingArea: true,
    coldPlunge: false,
    sauna: false,
    soundSystems: false,
    steamRoom: true,
    waterFountain: true,
  };

  //const Gym = useSelector((state) => state.selectedGym);
  const [gallery, setGallery] = useState([]);
  const [items] = useState({});

  const [isExpanded, setIsExpanded] = useState(false);

  const [scheduleData, setSchedule] = useState({});
  const daySelect = useSelector((state) => state.booking.selectedDate);
  const timeSelect = useSelector((state) => state.booking.selectedTime);

  const caretSymbol = isExpanded ? "▲" : "▼";
  const scheduleRef = useRef(null);

  const [isPanelOpen, setPanelOpen] = useState(false);

  const [checkoutMode, setCheckoutMode] = useState("light");

  const theme = useTheme();

  const openPanel = () => {
    setPanelOpen(true);
  };

  const closePanel = () => {
    setPanelOpen(false);
  };

  const getSchedule = () => {
    //console.log(Data);

    if (Data !== null) {
      // const response = await getSiteData(Data.slug); ///getMockData();
      // const gallery = await Data.gallery;

      setSchedule(Data.calendar.schedule);
      setGallery(Data.gallery);

      //console.log(gallery); 

    } else {
      const response = getMockData();
      const gallery = response.data.publicData.gallery;

      console.log(gallery);

      setSchedule(response.data.publicData.calendar.schedule);
      setGallery(gallery);
    }
  };

  useEffect(() => {
    getSchedule();

    setCapacity(
      Data.details.space.sqFeet !== null
        ? Data.details.space.sqFeet
        : getRandomNumber(15, 100)
    );
    setSqFeet(
      Data.details.space.sqFeet !== null
        ? Data.details.space.sqFeet
        : getRandomMultipleOf500(500, 2500)
    );
  }, []);

  useEffect(() => {
    //console.log(scheduleRef)
    if (scheduleData && daySelect !== null && scheduleRef !== null) {
      scheduleRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [daySelect]);

  const getRandomMultipleOf500 = (min, max) => {
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return Math.floor(randomNumber / 500) * 500;
  };

  const getRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const [capacity, setCapacity] = useState();
  const [sqFeet, setSqFeet] = useState();

  return (
    <Box
      sx={{
        padding: "10px 10px 0px",
        [theme.breakpoints.up('sm')]: {
          paddingTop: "20px", // Apply this padding-top when screen width is 640px or more
        },
      }}
    >
      <div>
        <AnimatedBackground />
      </div>
      <div
        style={{ ...spacingStyles.desktopMaxWidth }}
      >
        <div style={{ ...spacingStyles.desktopMaxWidth }}>
          <GymDetailsHeader Data={Data.profile} showProfilePic={true} mode="dark" />
          <div
            style={{
              position: "relative",
              ...spacingStyles.flexRow,
              ...spacingStyles.mt10,
              ...spacingStyles.mb10,
              ...spacingStyles.w100,
            }}
          >
            {gallery &&
              gallery.length > 3 &&
              [...Array(4).keys()].map((i) => (
                <img
                  key={i}
                  src={gallery[i].url}
                  className="prev-gallery"
                  alt=""
                  style={{
                    ...spacingStyles.mh2,
                    ...spacingStyles.br10,
                  }}
                />
              ))}
            {/* {gallery && gallery.length > 4 && (
            <div
              style={{
                aspectRatio: 1,
                position: "absolute",
                top: 0,
                right: 0,
                ...spacingStyles.w19,
                ...spacingStyles.mh2,
                ...spacingStyles.br10,
                borderRadius: "10px",
                overflow: "hidden",
                padding: "3px",
              }}
            >
              <div
                style={{
                  flex: 1,
                  backgroundColor: "#FF6D00A6",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "102%",
                  height: "100%",
                  ...spacingStyles.br10
                }}
              >
                <span style={{ color: "white", fontSize: 25, fontWeight: "500" }}>
                  +{gallery.length - 5}
                </span>
              </div>
            </div>
          )} */}
          </div>
          <div
            className="flexRow centerView"
            style={{
              ...spacingStyles.flexAround,
              ...spacingStyles.w100,
              ...spacingStyles.mb40,
            }}
          >
            <div className="flexRow" style={{ ...spacingStyles.pr10 }}>
              <div className="flexRow">
                {/* <Ionicons name="resize" color={spacingStyles.gray.color} size={20} /> */}
                <span className="gray h3" style={{ fontWeight: "500" }}>
                  Size:
                </span>
              </div>
              <span
                className="light_gray"
                style={{ fontWeight: "500", marginLeft: 5 }}
              >
                {sqFeet} sq ft
              </span>
            </div>
            <div className="flexRow">
              <div className="flexRow">
                {/* <Ionicons name="people" color={spacingStyles.gray.color} size={20} /> */}
                <span className="gray" style={{ fontWeight: "500" }}>
                  Capacity:
                </span>
              </div>
              <span
                className="light_gray"
                style={{ fontWeight: "500", marginLeft: 5 }}
              >
                {capacity} people
              </span>
            </div>
          </div>
          <div
            className="flexCol leftView"
            style={{
              ...spacingStyles.w100,
              ...spacingStyles.bbg,
              ...spacingStyles.mb20,
            }}
          >
            <h2 className="cust_header gray ">About:</h2>

            <p className="left" style={{ ...spacingStyles.mb0, color: theme.palette.text.primary }}>
              {isExpanded
                ? Data?.profile.descr
                : Data?.profile.descr.slice(0, 80)}
            </p>
            {Data?.profile.descr && Data.profile.descr.length > 80 ? (
              <div
                style={{ ...spacingStyles.mb10 }}
                onClick={() => {
                  setIsExpanded(!isExpanded);
                }}
              >
                <h3 className="h3 gray" style={{ ...spacingStyles.mt5 }}>
                  {isExpanded
                    ? "See Less " + caretSymbol
                    : "See More " + caretSymbol}
                </h3>
              </div>
            ) : null}
          </div>
          <div ref={scheduleRef}></div>

          <DetailsSchedule schedule={scheduleData} />

          <div className="footer-profile">
            <div
              className="flexRow flexAround footer-inner"
              style={{ ...spacingStyles.w100 }}
            >
              <div className="flexCol centerDiv">
                <div className="flexRow price">
                  <span className="h1 white" style={{ fontSize: 45 }}>
                    $15
                  </span>
                  <span className=" white" style={{ ...spacingStyles.pl5 }}>
                    /booking
                  </span>
                </div>
                <h3
                  className="footer-highlight gray"
                  style={{ ...spacingStyles.mt0 }}
                >
                  {" "}
                  <StarIcon /> with membership
                </h3>
              </div>
              <div
                style={{ ...spacingStyles.w50 }}
                onClick={() => {
                  if (daySelect !== null && timeSelect !== null) {
                    openPanel();
                  }
                }}
              >
                <Button
                  fill={
                    daySelect === null || timeSelect === null ? "outline" : "solid"
                  }
                  text="Book Now"
                  disabled={daySelect === null || timeSelect === null}
                ></Button>
              </div>
            </div>
          </div>

          <TrainerList latitude={Data.profile.location[0].lat} longitude={Data.profile.location[0].long} gymName={Data.profile.name} />

          <div style={{ ...spacingStyles.pt40 }}>
            <div style={{ ...spacingStyles.pt10 }}>
              <h2 className="cust_header left">Amenities:</h2>
            </div>
            <div
              className="flexWrap flexRow"
              style={{ ...spacingStyles.pt10, ...spacingStyles.pb10 }}
            >
              {items &&
                Object.entries(amenities).map(([item, value], a) => {
                  if (value && CustIcons[item]?.displayName !== "") {
                    return (
                      <div
                        className="flexRow chp-border"
                        style={{
                          ...spacingStyles.p5,
                          ...spacingStyles.mb5,
                          ...spacingStyles.mr5,
                        }}
                      >
                        <RenderIcons
                          iconName={item}
                          size={25}
                          color={colors.gray.color}
                        />
                        <span style={{ color: theme.palette.text.primary, ...spacingStyles.pl5 }}>
                          {CustIcons[item]?.displayName}
                        </span>
                      </div>
                    );
                  }
                  return null;
                })}
            </div>
          </div>
          <div>
            <div style={{ ...spacingStyles.pt10 }}>
              <h2 className="cust_header left">Facilities:</h2>
            </div>
            <div
              className="flexWrap flexRow"
              style={{ ...spacingStyles.pt10, ...spacingStyles.pb10 }}
            >
              {items &&
                Object.entries(facilities).map(([item, value], a) => {
                  if (value) {
                    return (
                      <div
                        key={a}
                        className="flexRow chp-border"
                        style={{
                          ...spacingStyles.p5,
                          ...spacingStyles.mr5,
                          ...spacingStyles.mb5,
                        }}
                      >
                        <RenderIcons
                          iconName={item}
                          size={25}
                          color={colors.gray.color}
                        />
                        <span
                          style={{
                            color: theme.palette.text.primary,
                            ...spacingStyles.p5,
                            ...spacingStyles.ml5,
                          }}
                        >
                          {CustIcons[item].displayName}
                        </span>
                      </div>
                    );
                  }
                  return null;
                })}
            </div>
          </div>
        </div>

        {daySelect !== null && timeSelect !== null && (
          <SlideInPanel

            isOpen={isPanelOpen}
            closePanel={closePanel}
            mode={checkoutMode}
            content={() => <Checkout isOpen={isPanelOpen} mode="light" />}
          />
        )}
      </div>
    </Box>
  );
};

export default GymDetails;
