import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"; // Fetch state from Redux store
import { addRoleID } from "../../Services/Redux/Slices/User.Slice";
import { addAuthRoleID } from "../../Services/Redux/Slices/Auth.Slice";
import { Typography, Box, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { updateRole } from "../../Services/APIHandlers/ProfileApi";
import "../../assets/styles/global.css"; // Reuse your global styles

import OnboardingButton from "../../Components/Common/Buttons/OnboardingButton";
import { Button } from "../../Components/Common/Buttons/Button";

import CleanWrapper from "../../Components/Common/CleanWrapper";

export default function UserType() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme(); // Use the global theme provided by the App

  const [userType, setUserType] = useState(null); // Track selected user type

  // Fetch auth state (isAuthenticated and token) from Redux store
  const auth = useSelector((state) => state.auth);
  const token = auth.token;

  const options = [
    { value: "Trainer", label: "Trainer", icon: "treadmill" },
    { value: "Fitness Facility", label: "Fitness Facility", icon: "gym" },
  ];

  const loader = async () => {
    if (!token || token === "") {
      localStorage.clear(); // Clear local storage if token is invalid
      return navigate("/");
    }
  };

  useEffect(() => {
    loader();
  }, [navigate, token]); // Added token as dependency to refresh if auth state changes

  const submitType = async (role_id) => {
    const response = await updateRole(role_id, token);
    dispatch(addRoleID(role_id)); // Dispatch role_id to Redux
    dispatch(addAuthRoleID(role_id));
    handleUserTypeSuccess(role_id); // Navigate based on role
  };

  const handleUserTypeSuccess = (role_id) => {
    switch (role_id) {
      case 2:
        navigate("/SiteOnboarding");
        break;
      case 3:
        navigate("/TrainerOnboarding");
        break;
      default:
        navigate("/GymDash");
        break;
    }
  };

  const handleClick = (selectedType) => {
    setUserType(selectedType); // Set selected user type (Trainer or Fitness Facility)
  };

  const handleGetStartedClick = () => {
    if (userType === "Trainer") {
      submitType(3); // Submit trainer role ID
    } else if (userType === "Fitness Facility") {
      submitType(2); // Submit fitness facility role ID
    }
  };

  return (
    <CleanWrapper>
      <Box
        sx={{
          maxWidth: "640px",
          height: "100%",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "0 20px",
        }}
      >
        <Typography
          sx={{
            marginBottom: 6,
          }}
          variant="h4"
          gutterBottom
          color={theme.palette.text.primary}

        >
          What type of Hopper are you?
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
          }}
        >
          {options.map((option) => (
            <OnboardingButton
              key={option.value}
              selected={userType === option.value}
              text={option.label}
              onClick={() => handleClick(option.value)}
              showCusIcon={false}
              cusIconName={option.icon} // Pass the appropriate icon for each option
              iconWidth={25}
              iconHeight={25}
            />
          ))}
        </Box>

        <Box className="onboarding-setup-cta" sx={{ pt: 6, width: "100%", margin: "0 auto" }}>
          <Button
            text={"Continue to Setup"}
            onClick={handleGetStartedClick}
            disabled={!userType} // Disable the button if no userType is selected
            fill="" // You can pass different styles here (e.g., "outline" or "inactive")
          />
          <Typography
            variant="body2"
            sx={{ mt: 2 }}
            color={theme.palette.text.primary} // Explicitly set color based on theme
          ></Typography>
        </Box>
      </Box>
    </CleanWrapper>
  );
}
