import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Typography } from "@mui/material"; // Importing MUI components
import { spacingStyles } from "../../assets/styles/global"; // Assuming these are custom styles
import OTPForm from "../../Components/Forms/OTPForm";
import CleanWrapper from "../../Components/Common/CleanWrapper";
import { useTheme } from '@mui/material/styles';

export default function OTP() {
  const navigation = useNavigate();
  const location = useLocation();
  const role_id = location.state?.role_id;

  const theme = useTheme(); 

  const handleOTPSuccess = (role_id) => {
    switch (role_id) {
      case "2":
        navigation("/GymDash");
        break;
      case "3":
        navigation("/TrainerDash");
        break;
      default:
        navigation("/UserType");
        break;
    }
  };

  return (
    <CleanWrapper>
      <Box
        sx={{
          ...spacingStyles.pt10h,
          ...spacingStyles.formSpace,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          color: theme.palette.text.primary
        }}
      >

        <Typography variant="h4" sx={{ mt: 2, textAlign: "center" }}>
          Verify your ID
        </Typography>

        <OTPForm onSuccess={handleOTPSuccess} />
      </Box>
    </CleanWrapper>
  );
}
