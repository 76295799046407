import React, { useEffect } from "react";

// This component will add the 'clean' class to the content-container when mounted,
// and remove it when unmounted.
const CleanWrapper = ({ children }) => {
  useEffect(() => {
    const contentContainer = document.querySelector(".content-container");
    const mobileFooterContainer = document.querySelector(".mobile-footer");

    // Add the "clean" class when the component mounts
    if (contentContainer) {
      contentContainer.classList.add("clean");
      mobileFooterContainer.classList.add("clean");
    }

    // Cleanup: Remove the "clean" class when the component unmounts
    return () => {
      if (contentContainer) {
        contentContainer.classList.remove("clean");
        mobileFooterContainer.classList.remove("clean");
      }
    };
  }, []);

  // Return children so you can wrap your page or sections with this component
  return <>{children}</>;
};

export default CleanWrapper;