import React, { useEffect, useContext } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import AuthContext from "../../Navigation/AuthWrapper";
import Setup from "../Wizard/SiteOnboarding/Setup";
import DemographicInfo from "../Wizard/SiteOnboarding/DemographicInfo";
import MatchMeTopic from "../Wizard/SiteOnboarding/MatchMeTopic";
import LocationSetup from "./SiteOnboarding/LocationSetup";
import ScheduleSetup from "./SiteOnboarding/ScheduleSetup";
import { useSnackbar } from "../../Navigation/AuthWrapper";
import TrainerCert from "./SiteOnboarding/TrainerCert.js";
import TrainerInsurance from "./SiteOnboarding/TrainerInsurance.js";
import SetupComplete from "../../Screens/Wizard/SiteOnboarding/SetupComplete";

import ProfilePic from "./SiteOnboarding/ProfilePicUpload.js"; 

import CleanWrapper from "../../Components/Common/CleanWrapper";
import { Box } from "@mui/material"; // MUI components

const screens = {
  Setup: { component: Setup, next: "DemographicInfo", prev: null, screenNum: 0 },
  DemographicInfo: { component: DemographicInfo, next: "ProfilePic", prev: "Setup", screenNum: 1 },
  ProfilePic: { component: ProfilePic, next: "Certification", prev: "DemographicInfo", screenNum: 1 },
  Certification: { component: TrainerCert, topic: "Certification", next: "Insurance", prev: "ProfilePic", screenNum: 2 },
  Insurance: { component: TrainerInsurance, topic: "Insurance", next: "Done", prev: "Certification", screenNum: 3 },
  SetupComplete: { component: SetupComplete, next: "Done", screenNum: 8, prev: null },
  Done: { component: "Done", prev: "Schedule" },
  Skip: { component: "Skip", prev: null },

  Coach: { component: MatchMeTopic, topic: "coach", next: "Coach", prev: "DemographicInfo", screenNum: 2 },
  Goal: { component: MatchMeTopic, topic: "goal", next: "Area", prev: "Coach", screenNum: 3 },
  Area: { component: MatchMeTopic, topic: "area", next: "Motivate", prev: "Goal", screenNum: 4 },
  Motivate: { component: MatchMeTopic, topic: "motivate", next: "Success", prev: "Area", screenNum: 5 },
  Success: { component: MatchMeTopic, topic: "success", next: "nonsuccess", prev: "motivate", screenNum: 6 },
  nonsuccess: { component: MatchMeTopic, topic: "nonsuccess", next: "Location", prev: "Success", screenNum: 7 },
  Location: { component: LocationSetup, next: "Schedule", prev: "nonsuccess" },
  Schedule: { component: ScheduleSetup, next: "SetupComplete", prev: "Insurance" },
};

const TrainerOnboarding = ({ activeScreen, setActiveScreen }) => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    // Redirect if role_id is not trainer
    if (auth.role_id === 2) {
      navigate("/SiteOnboarding"); // Redirect site owners
    }
  }, [auth.role_id, navigate]);

  const renderScreen = () => {
    const screenInfo = screens[activeScreen];
    if (!screenInfo) {
      return <Navigate to="/TrainerDash" />; // Default redirect
    }

    const ScreenComponent = screenInfo.component;

    return ScreenComponent ? (
      <ScreenComponent
        {...(screenInfo.props || {})}
        topic={screenInfo.topic}
        goToStep={(screen) => setActiveScreen(screen)}
        nextAction={() => setActiveScreen(screenInfo.next)}
        previousAction={() => setActiveScreen(screenInfo.prev || "Setup")}
      />
    ) : null;
  };

  return (
   
      <Box className="site-onboard">
        {renderScreen()}
      </Box>
 
  );
};

export default TrainerOnboarding;
